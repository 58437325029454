import React from 'react'
import { useSelector } from 'react-redux'
import Head from '../layout/Head'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function Profile() {
    const {firstname,lastname} = useSelector(state => state.auth.user)
    const userId = useSelector(state => state.auth.userId)
    const projects = useSelector(state => state.project.userProjects)
    return (
        <>
        <Head title="Profile"/>
        <div className="row">
            <div className="col s12 m2 l4 center grey darken-3 white-text">
                <img className="circle userProfile-image" src="/images/wigglytuff.jpeg" alt="" />
                <h5>{firstname} {lastname}</h5>
                <p>{userId}</p>
                <ul>
                    <li>Mailbox<span className="new badge orange white-text">4</span></li>
                    <li><a href="#">Profile</a></li>
                    <li><a href="#">Profile</a></li>
                </ul>
            </div>
            <div className="col s12 m10 l8">
                <div className="col s4">
                    <div className="container">
                    <CircularProgressbarWithChildren value={4} maxValue={10} counterClockwise={true} styles={{path:{stroke:`#F98404`},trail:{stroke:'rgb(200 194 188 / 45%)'}}}>
                        <h3>4</h3>
                        <h5>Projects</h5>
                    </CircularProgressbarWithChildren>
                    </div>
                </div>
                <div className="col s4">
                    <div className="container">
                        <CircularProgressbarWithChildren value={2} maxValue={10} counterClockwise={true} styles={{path:{stroke:`#F98404`},trail:{stroke:'rgb(200 194 188 / 45%)'}}}>
                        <h3>2</h3>
                        <h5>Collections</h5>
                    </CircularProgressbarWithChildren>
                    </div>
                    
                </div>
                <div className="col s4">
                    <div className="container">
                        <CircularProgressbarWithChildren value={7} maxValue={10} counterClockwise={true} styles={{path:{stroke:`#F98404`},trail:{stroke:'rgb(200 194 188 / 45%)'}}}>
                        <h3>7</h3>
                        <h5>Wags</h5>
                    </CircularProgressbarWithChildren>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
