import React,{useState} from 'react'
import { useDispatch } from 'react-redux'
import Head from '../layout/Head'
import { useNavigate } from 'react-router-dom'
import { newUser } from '../../store/actionCreator'
import { useFirebase, useFirestore } from 'react-redux-firebase'

const toggleErrorMessage = err => {
    switch(err.code) {
        case "auth/weak-password":
            document.querySelector("input#password").classList.add("invalid")
            document.querySelector("span#passwordMessage").setAttribute("data-error","The password is too weak.")
            break
        case "auth/invalid-email":
            document.querySelector("input#username").classList.add("invalid")
            document.querySelector("span#usernameMessage").setAttribute("data-error", err.message)
            break
        case "auth/email-already-in-use":
            document.querySelector("input#username").classList.add("invalid")
            document.querySelector("span#usernameMessage").setAttribute("data-error",err.message)
            break
        default:
            return
    }
}
// firebase register + add to firebase user
export default function SignUp() {
    const firestore = useFirestore()
    const firebase = useFirebase()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [firstname, setFirstname] = useState("");
    const [imageProfile, setimageProfile] = useState("/images/defaulticon.png");
    const [lastname, setLastname] = useState("");
    const [displayName, setdisplayName] = useState("");
    const [email, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const setImage = e => {
        const url = URL.createObjectURL(e.target.files[0])
        setimageProfile(url)
    }
    const submitForm = () => {
        if (firstname !== "" && lastname !== "" && email !== "" && password !== "") {
            // async create userin firebase auth and firestore info (firstname,lastname,username,password)
            firebase.createUser({email,password},{username:displayName}).then(userCred => {
                const userId = userCred.user.uid
                return firebase.uploadFile("images",imageProfile).then(snapShort => {return {userId,imageProfileUrl:snapShort.ref.getDownloadURL()}}).catch(err => console.log("Image Uploading Error",err))
            }).then(({userId,imageProfileUrl}) => {
                // add to firebase 'user' collection
                return firestore.collection('users').doc(userId).set({firstname,lastname,email,displayName,imageProfileUrl})
            }).then(user => {
                dispatch(newUser(firstname,lastname,user.id)).then(() => navigate("/",{ replace: true }))
            }).catch(err => {
                toggleErrorMessage(err)
            })
        }
    }
    return (
        <>
        <Head title="Sign Up"/>
        <div className="container">
        <h1>Sign Up</h1>
        <div className="col s12">
            <div className="row baligncontainer">
            <div className="col s10 m3 l2" style={{marginBottom:"10px"}}>
                <img src={imageProfile} alt="profileImage" className="responsive-img circle"/>
            </div>
            <div className="col s10 m6 bottom-align">
                <label htmlFor="imageProfile">change profile image: </label>
                <input type="file" name="imageProfile" accept="image/*" id="imageProfile" onChange={e => setImage(e)} /></div>
            </div>
            <div className="row">
                <div className="input-field col s6">
                    <label htmlFor="firstname">First Name</label>
                    <input type="text" name="firstname" id="firstname" className="validate" onChange={(e) => setFirstname(e.target.value)} required/>
                </div>
                <div className="input-field col s6">
                    <label htmlFor="lastname">Last Name</label>
                    <input type="text" name="lastname" id="lastname" className="validate" onChange={(e) => setLastname(e.target.value)} required/>
                </div>
                <div className="col s12 input-field">
                    <label htmlFor="displayName">Display Name</label>
                    <input type="text" name="displayName" id="displayName" onChange={(e) => setdisplayName(e.target.value)} className="validate" />
                    <span className="helper-text">Name visible to other users</span>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <label htmlFor="username">Username (email)</label>
                    <input className="validate" type="email" name="username" id="username" onChange={(e) => setUsername(e.target.value)} required/>
                    <span id="usernameMessage" className="helper-text" data-error=""></span>
                </div>
                <div className="input-field col s12">
                    <label htmlFor="password">Password</label>
                    <input className="validate" type="password" name="password" id="password" minLength="6" onChange={(e) => setPassword(e.target.value)} required/>
                    <span id="passwordMessage" className="helper-text" data-success="success" data-error="">must contain minimun of 6 characters</span>
                </div>
            </div>
            <button className="btn green lighten-2" onClick={()=> submitForm()}>Sign Up</button>
        </div>
        </div>
        </>
    )
}
