import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import '../../styles/global.css'
import SignIn from "./SignIn"
import SignOut from './SignOut'

export default function Navbar() {
    const auth = useSelector(state => state.firebase.profile)
    return (
        <nav className="transparent z-depth-0">
        <div className="nav-wrapper">
            <Link to="/" className="logo"><img src="/images/sherl-icon-transparent.png" alt="appLogo" className="navbar-logo"/></Link>
            {(isEmpty(auth))?<SignIn/>:<SignOut />}
        </div></nav>
    )
}
