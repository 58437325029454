
// add project function called by dispatch()
export const addProject = (projectId,project) => {
    return {type:'ADD_PROJECT',projectId,project}
}
// delete project
export const delProject = projectId => {
    return {type:'DELETE_PROJECT',projectId}
}
// create user
export const newUser = (firstname,lastname,userId) => {
    return {type:'CREATE_USER',firstname,lastname,userId}
}
// sign into acc
export const signIn = (userId,user) => {
    return {type:'SIGN_IN',userId,user}
}
// sign out acc
export const signOutUser = () => {
    return {type:'SIGN_OUT'}
}