import React,{useState} from 'react'
import { useFirebase } from 'react-redux-firebase'
import { motion } from 'framer-motion'

const titleAnimation = {
    slideRight: {
        left:0
    },
    initial: {
        left:"100%"
    }
}
const ballVariants = {
    bounce: {
        y:[25,12,25],
        backgroundColor:["#9d9d01","#e0c800"],
        transition: {
            type:"spring",
            repeat:3,
            bounce:0.5,
            duration:Infinity,
            repeatDelay:0.9,
        }
    }
}
export default function UploadFileBtn(props) {
    const [condition, setCondition] = useState(false)
    const firebase = useFirebase();
    const upload = () => {
        setCondition(true)
        firebase.uploadFile(props.file.name,props.file).then(result => {
            if (result.uploadTaskSnapshot.bytesTransferred % result.uploadTaskSnapshot.totalBytes === 0) {
                return result.uploadTaskSnapshot.ref.getDownloadURL()
            }
        }).then(url => {

        })
    }
    return (
        <div className="loaderBtn" onClick={() => upload()}>
            <motion.div variants={titleAnimation} initial="initial" animate={condition?"initial":"slideRight"}>
            <motion.div className="ballContainer" initial={{}} animate="bounce" transition={{staggerChildren:0.3}} >
            <motion.div variants={ballVariants} className="ball"></motion.div>
            <motion.div variants={ballVariants} className="ball"></motion.div>
            <motion.div variants={ballVariants} className="ball"></motion.div></motion.div>
            <p>Upload</p></motion.div>
        </div>
    )
}
