export default function parseDatenTime() {
    this.toMonth = month => {
        switch (month) {
            case 1:
                return "Jan"
            case 2:
                return "Feb"
            case 3:
                return "Mar"
            case 4:
                return "Apr"
            case 5:
                return "May"
            case 6:
                return "Jun"
            case 7:
                return "Jul"
            case 8:
                return "Aug"
            case 9:
                return "Sep"
            case 10:
                return "Oct"
            case 11:
                return "Nov"
            default:
                return "Dec"
        }
    }
    this.toDDMMYY = date => {
        const m = date.getMonth()
        const day = date.getDay() < 10?`0${date.getDay()}`:date.getDay()
        const month = this.toMonth(m)
        return `${day} ${month}, ${date.getFullYear()}`
    }
    this.toHHMM = date => {
        const min = date.getMinutes()
        const hour = date.getHours()
        if (hour > 12) {
            return `${hour-12}:${min}PM`
        }
        return `${hour}:${min}AM`
    }
}