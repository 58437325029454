import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { signOutUser } from '../../store/actionCreator'
import { Link } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'

export default function SignOut() {
    const firebase = useFirebase()
    const {firstname,lastname} = useSelector(state => state.firebase.profile)
    const dispatch = useDispatch()
    const signOut = () => {
        firebase.logout().then(() => {
            dispatch(signOutUser())
        })
    }
    return (
        <>
        <ul className="right hide-on-med-and-down">
            {/* <li><Link to="/projects" className="black-text">Browse Projects</Link></li> */}
            <li><Link to="/createproject" className="black-text">New Recipe</Link></li>
            <li><Link to="/myprojects" className="black-text">My Recipes</Link></li>
            <li><Link to="/" className="black-text" onClick={() => signOut()}>Sign Out</Link></li>
            <li><Link to="/profile" className="btn amber black-text center user-btn">{`${firstname}.${lastname}`}</Link></li>
        </ul>
        <a href="#" data-target="mobile-link" className="sidenav-trigger hide-on-med-and-up">
        <i className="material-icons hide-on-med-and-up">menu</i></a>
        <ul className="sidenav hide-on-med-and-up" id="mobile-link">  
        <li><Link to="/createproject" className="black-text">New Recipe</Link></li>
            <li><Link to="/myprojects" className="black-text">My Recipes</Link></li>
            <li><Link to="/" className="black-text" onClick={() => signOut()}>Sign Out</Link></li>
            <li><Link to="/profile" className="btn amber black-text center user-btn">{`${firstname}.${lastname}`}</Link></li>
        </ul>
        </>
    )
}
