
const initState = {
    postsArray:[],
    comments:{}
}
// async firestore get posts and comments

const postReducer = (state=initState,action) => {
    switch(action.type) {
        default:
            return state
    }
}
export default postReducer