import firebase from "firebase/app"
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDNllCmD-wL1Q-u7oC_E6HhQZTd-J1_6kI",
    authDomain: "sherl-app.firebaseapp.com",
    projectId: "sherl-app",
    storageBucket: "sherl-app.appspot.com",
    messagingSenderId: "939078020816",
    appId: "1:939078020816:web:09d20b25231fde44cbb8d5",
    measurementId: "G-2TYH1NFDQ4"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({timestampsInSnapshots:true,merge:true})

export default firebase;