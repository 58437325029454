import React, {useState} from 'react'
import Head from '../layout/Head'
import FoodCard from '../projects/FoodCard'
import PostCards from '../layout/PostCards'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { motion } from 'framer-motion';
import '../../styles/imageGrid.css'

const cardContainerAnimation = {
    visible: {
        opacity:1,
        transition:{
            delay:0.2,when:'beforeChildren',staggerChildren:0.5
        }
    }
}
const parseFoodCard = (projects,newState) => {
    for (let x=0;x<projects.length;x++) {
        const {id,title,description,image_path,author} = projects[x]
        // if (x === projects.length-1) {
        //     newState.push(<FoodCard key={id} author={author} cardType="span-col-3 span-row-2 card-2" id={id} title={title} content={description} picture={imageUrl||"/images/coffeecake.jpeg"}/>)
        // }
        // else if (x === 2 || x === 4 || x === 14 || x === 17) {
        //     newState.push(<FoodCard key={id} author={author} cardType="span-col-2 card-3" id={id} title={title} content={description} picture={imageUrl||"/images/coffeecake.jpeg"}/>)
        // }
        // else if (x === 3 || x === 8) {
        //     newState.push(<FoodCard key={id} author={author} cardType="span-col-2 span-row-2 card-4" id={id} title={title} content={description} picture={imageUrl||"/images/coffeecake.jpeg"}/>)
        // }
        if (x == 3 || x === 5 || x === 8 || x === 13 ) {
            newState.push(<FoodCard key={id} author={author} cardType="span-col-2 span-row-2 card-4" id={id} title={title} content={description} picture={image_path||"/images/coffeecake.jpeg"}/>)
        }
        else {
            newState.push(<FoodCard key={id} author={author} cardType="card-1" id={id} title={title} content={description} picture={image_path||"/images/coffeecake.jpeg"}/>)
        }
    }
    return newState
}
// user = {username:"",usertag:"",imageProfile:"",post:"",timestamp:""}
export default function Home() {
    useFirestoreConnect(() => [{collection:'projects',queryParams: [ 'limitToFirst=10']},{collection:"posts"}])
    const [toggleFeed, setToggleFeed] = useState(false)
    const projects = useSelector(state => {
        if (state.firestore.ordered.projects) {
            let newState = []
            let projectArray = state.firestore.ordered.projects
            // if (state.firestore.ordered.projects.length <= 18) {
            //     const cycleCount = Math.floor(state.firestore.ordered.projects.length / 18)
            //     for (let x=0;x<cycleCount;x++) {
            //         projectArray = state.firestore.ordered.projects.slice((x*18),(x*18+18))
            //         newState = parseFoodCard(projectArray,newState)
            //     }
            // }
            // else {
                projectArray = state.firestore.ordered.projects.slice(0,30)
                parseFoodCard(projectArray,newState)
            // }
        return newState
    }});
    const posts = useSelector(state => {
        const newState = []
        if (state.firestore.ordered.posts) {
            for (let post of state.firestore.ordered.posts) {
                newState.push(<PostCards key={post.id} data={post}/>)
            }
        }
        return newState
    })
    return (
        <>
            <Head title="SHERL"/>
            <div style={{position:"relative"}}>
            <button className="btn-floating feed-btn orange lighten-2 btn-medium" onClick={() => setToggleFeed(!toggleFeed)}><i className="material-icons small">rss_feed</i></button>
            <div className="row">
                <motion.div variants={cardContainerAnimation} animate="visible" className="col s12">
                    <div className="grid">
                        {projects}
                    </div>
                </motion.div>
            </div>
            <div className="row">
                <motion.div initial={{x:'100%'}} animate={() => toggleFeed?{x:0}:{}} className="col s12 m4 offset-m8 feed-container">
                    {posts}
                </motion.div>
            </div>
            </div>
        </>
    )
}
