import React, {useState, useEffect} from 'react'
import M from 'materialize-css'
import Head from '../layout/Head'
import { useSelector } from 'react-redux'
// import { addProject } from '../../store/actionCreator'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { useNavigate } from 'react-router'
import UploadFileBtn from '../layout/UploadFileBtn'

export default function CreateProject() {
    const navigate = useNavigate()
    const firebase = useFirebase()
    const firestore = useFirestore()
    // const dispatch = useDispatch()
    const [image, setImage] = useState("")
    const [ingredients, setIngredients] = useState("")
    const [methods, setMethods] = useState("")
    const [title, setTitle] = useState("")
    const [tags, setTags] = useState([])
    const [description, setDescription] = useState("")
    // return logged in user info
    const user = useSelector(state => state.firebase.profile)
    const userId = useSelector(state => state.firebase.auth.uid)
    const addTags = e => {
        const tagslist = e.target.selectedOptions
        const newTagsList = []
        for (let x=0;x<tagslist.length;x++) {
            newTagsList.push(tagslist[x].label)
        }
        setTags(newTagsList)
    }
    const loadImage = e => {
        setImage(URL.createObjectURL(e.target.files[0]))
    }
    const createProject = async() => {
        // async add project to firestore projects collection
        const project = {title,description,tags,ingredients,methods,author:user.username,timestamp:firestore.Timestamp.fromDate(new Date())}
        // add projectId to user project
        const {id} = await firestore.collection("projects").add(project).catch(err => console.log(err))
        // upload image to firebase storage
        firebase.uploadFile(`images`,image).then(snapShots => {
            if (snapShots.bytesTransferred % snapShots.totalBytes === 0) {
                return snapShots.ref
            }
            else {
                return false
            }
        }).then(imageUrl => {
            // update imageUrl in project
            return firestore.collection("projects").doc(id).update({imageUrl})
        }).then(() => {
            // update user project id
            return firestore.collection("users").doc(userId).update({userProjects:[...user.userProjects,id]})
        }).then(() => {
            navigate('/', {replace: true})
        }).catch(err => console.log('Error in file upload',err))
    }
    useEffect(() => {
        const elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [])
    return (
        <>
        <Head title="Create Recipe"/>
        <div className="container">
            <div className="row">
                <div className="col s10" style={{marginBottom:"10px"}}>
                    <label htmlFor="image">Recipe Image </label>
                    <input type="file" name="image" id="image" accept="image/*" onChange={e => loadImage(e)} />
                </div>
                <div className="col s10 m5 l4">
                   <img src={image} alt="foodImage" className="responsive-img" /> 
                </div>
            </div>
        <div className="row">
        <div className="input-field col s12 m6">
            <select name="tags" id="tags" multiple onChange={e => addTags(e)}>
                <option className="black-text" value="Breakfast">Breakfast</option>
                <option value="Brunch">Brunch</option>
                <option value="Lunch">Lunch</option>
                <option value="Dessert">Dessert</option>
                <option value="Snacks">Snacks</option>
                <option value="Drinks">Drinks</option>
                <option value="Appetizers">Appetizers</option>
                <option value="Sides">Sides</option>
                <option value="Breads">Breads</option>
            </select>
            <label htmlFor="tags">Categories</label>
            </div></div>
        <div className="row">
            <div className="input-field col s12 m6">
                <label htmlFor="title">Title</label>
                <input type="text" name="title" id="title" onBlur={e => setTitle(e.target.value)}/>
            </div>
            <div className="input-field col s12">
                <label htmlFor="description">description</label>
                <textarea className="materialize-textarea" name="description" id="description" onBlur={e => setDescription(e.target.value)}></textarea>
            </div>
        </div>
        <div className="row">
            <div className="input-field col s6 m3 l2">
                <label htmlFor="serving">serving size</label>
                <input type="number" name="serving" id="serving"/>
            </div>
            <div className="input-field col s6 m3 l3">
                <label htmlFor="prepTime">prep time (minutes)</label>
                <input type="number" name="prepTime" id="prepTime"/>
            </div>
        </div>
        <div className="row">
            <div className="input-field col s10 m5 l4">
                <label htmlFor="ingredients">Ingredients</label>
                <textarea name="ingredients" id="ingredients" className="materialize-textarea" onChange={(e) => setIngredients(e.target.value)} ></textarea>
            </div>
            <div className="input-field col s10 offset-m1 offset-l1 m6 l7">
                <label htmlFor="methods">Directions</label>
                <textarea name="methods" id="methods" className="materialize-textarea" onChange={(e) => setMethods(e.target.value)}></textarea>
            </div>
        </div>
        <div className="row">
            <div className="col s10 m5 l4">
            {(ingredients === "") || (<><h5>Ingredients</h5>
        <ul className="browser-default">{ingredients.split("\n").map(ingre => <li key={ingre}>{ingre}</li>)}</ul></>)}
            </div>
            <div className="col s10 offset-m1 offset-l1 m6 l7">
            {(methods === "") || (<><h5>Directions</h5>
        <ol className="browser-default">{methods.split("\n").map(method => <li key={method}>{method}</li>)}</ol></>)}
            </div>
        </div>
        <p>author: {user.username}</p>
        <UploadFileBtn file={image} />
        {/* <LoadingButton onClick={() => checkInputs()} title="Confirm" condition={submitState} /> */}
        </div>
        </>
    )
}
