const initState = {
    userId:"",
    user:{firstname:"",lastname:"",displayName:"",recipes:[],points:0},
    status:false
}
const authReducer = (state=initState,action) => {
    switch(action.type) {
        case "SIGN_IN":
            return {...state,userId:action.userId,user:action.user,status:true}
        case "SIGN_OUT":
            return initState
        case "CREATE_USER":
            const {firstname,lastname,userId} = action
            return {
                ...state,
                userId,
                user:{...state.user,firstname,lastname},
                status:true
            }
        default:
            return state
    }
}
export default authReducer