import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import M from 'materialize-css'
export default function SignIn() {
    useEffect(() => {
        const elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }, [])
    return (
        <>
        <ul className="right hide-on-small-only">
            {/* <li><Link to="/projects" className="black-text">Projects</Link></li> */}
            <li><Link to="signup" className="black-text">Sign Up</Link></li>
            <li><Link to="signin" className="black-text">Sign In</Link></li>
        </ul>
        <a href="#" data-target="mobile-link" className="sidenav-trigger hide-on-med-and-up">
        <i className="material-icons hide-on-med-and-up">menu</i></a>
        <ul className="sidenav hide-on-med-and-up" id="mobile-link">
            <li><Link to="signup" className="black-text">Sign Up</Link></li>
            <li><Link to="signin" className="black-text">Sign In</Link></li>
        </ul></>
    )
}
