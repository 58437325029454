import React,{useEffect} from "react";
import M from 'materialize-css';
// pages and page components
import Navbar from './components/nav/Navbar'
import Loader from './components/layout/Loader'
import ErrorPage from "./components/pages/404";
import About from './components/pages/About';
import Home from "./components/pages/Home";
import Profile from "./components/pages/Profile";
import ProjectPage from "./components/pages/ProjectPage";
import CreateProject from './components/pages/CreateProject';
import SignIn from './components/pages/SignIn';
import SignUp from "./components/pages/SignUp";
// router and redux
import { useSelector } from "react-redux";
import { Routes,Route,BrowserRouter } from "react-router-dom";
import { isEmpty, isLoaded, useFirestore } from "react-redux-firebase";

export default function App() {
    const auth = useSelector(state => state.firebase.auth)
    const firestore = useFirestore()
    useEffect(() => {
        M.AutoInit();
    },[])
    if (!isLoaded(firestore)) return <Loader/>
  return (
      <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home/>}></Route>
          <Route path="about" element={<About />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          {!isEmpty(firestore)?<Route path="project/:id" element={<ProjectPage/>}></Route>:<Route path="project/:id" element={<Loader/>}></Route>}
          {isEmpty(auth)?<Route path="createproject" element={<ErrorPage/>}></Route>:<Route path="createproject" element={<CreateProject/>}></Route>}
          <Route path="signin" element={<SignIn/>}></Route>
          <Route path="signup" element={<SignUp/>}></Route>
          <Route path="404" element={<ErrorPage/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}