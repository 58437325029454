import React from 'react'

export default function Loader() {
    return (
        <div className="container valign-wrapper">
            <div className="progress">
                <div className="indeterminate" style={{backgroundColor:"#e0790f"}}></div>
            </div>
        </div>
    )
}
