import React from 'react'
import {Link} from 'react-router-dom';
import { motion } from 'framer-motion';
import { useFirestore } from 'react-redux-firebase';

const overlayAnimation = {
    hidden:{
        y:'0'
    },
    visible:{
        y:'-50%',
        backgroundColor: "#f5f5f59c",
        transition: {
            ease:"easeInOut",
            bounce:0.7,
            duration:0.35,
        }
    }
}
const cardAnimation = {
    hidden: {
        opacity:0,
        y:"100%"
    },
    visible: {
        opacity:1,
        y:0,
        transition:{
            ease:"easeIn",
            duration:0.3
        }
    }
}
const bounceUpAndDown = {
    bounce: {
        y:[0,-8,0],
        transition: {
            type:"spring",
            bounce:0.5,
            duration:1,
            repeat:3
        }
    }
}
export default function FoodCard(props) {
    const firestore = useFirestore();
    const {imageProfileUrl} = firestore.collection("users").doc(props.author)
    return (
        <motion.div variants={cardAnimation} initial="hidden" animate="visible" className={`card hoverable cardFood ${props.cardType}`} style={{backgroundImage:`url(${props.picture})`}}>
            <motion.div variants={overlayAnimation} initial="hidden" animate="" whileHover="visible" className="overlay">
                <div className="page">
                    <img src={imageProfileUrl||"/images/sherl-icon.png"} alt="profileImage" className="circle cardProfile hide-on-small-only" />
                    <div className="overlay-container">
                        <motion.img src="/images/upswipe.svg" width="35px" variants={bounceUpAndDown} animate="bounce"></motion.img>
                        <h5 className="overlay-title">{props.title.length >= 27 ? props.title.substring(0, 27) + "..." : props.title}</h5>
                    </div>
                </div>
                <div className="page">
                <h5 className="overlay-title black-text" style={{textDecoration:'underline'}}>{props.title.length >= 27 ? props.title.substring(0, 27) + "..." : props.title}</h5>
                <p className="overlay-description">{props.content && props.content.length > 55 ? props.content.substring(0, 55) + "..." : props.content}</p>
                <Link className="white-text overlay-btn" to={`/project/${props.id}`}><button className="project-btn">View Project</button></Link></div>
            </motion.div>
        </motion.div>
    )
}
