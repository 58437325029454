import React from 'react'
import { useSelector } from 'react-redux'
import Head from '../layout/Head'
import parseDatenTime from '../../components/layout/parseDatenTime'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router'

const datefunction = new parseDatenTime()
const parseDate = (time) => {
    const date = time.toDate()
    const month = datefunction.toMonth(date.getMonth())
    return `${date.getDate()}.${month}.${date.getFullYear()}`
}
const ProjectPage = () => {
    const {id} = useParams()
    useFirestoreConnect(() => [{collection:"projects",doc:id},'users'])
    const projects = useSelector(state => state.firestore.data.projects)
    const users = useSelector(state => state.firestore.data.users)
    if (!projects) return null
    const {title,description,ingredients,methods,author,tags,timestamp,image_path} = projects[id]
    let authorname;
    if (users) {
    Object.keys(users).forEach(u => {
        if (users[u].uid === author) {
            authorname = users[u].displayName
        }
    })}
    return (
        <>
        <Head title={title}/>
        <div style={{backgroundImage:"url('/images/background-image.jpeg'),linear-gradient(180deg, rgba(58,58,59,1) 2%, rgba(157,161,162,1) 100%)",backgroundSize:"cover",minHeight:"130vh",backgroundBlendMode:0.7}}>
        <img src={image_path} alt="Recipe Image" className="responsive-image" style={{height:"60vh",width:"100%",objectFit:"cover",objectPosition:"50% 60%"}}/>
        <div className="container white-text">
            <h2 className="card-title">{title}</h2>
            <div>
                <cite className="authorcite">{authorname}</cite><cite className="authorcite">{parseDate(timestamp)}</cite>
            </div>
            <div className="tagscontainer">
                {tags.map(tag => (<div key={tag} className="chip amber black-text">{tag}</div>))}
            </div>
            <p>{description}</p>
            <h4>Ingredients</h4>
            <ul className="browser-default list-item">{ingredients.map(ingre => <li key={ingre}>{ingre}</li>)}</ul>
            <div className="col m1 hide-on-small-only "><div className="vertical-divider"></div></div>
            <h4>Methods</h4>
            <ol className="browser-default list-item">{methods.map(method => <li key={method}>{method}</li>)}</ol>

        </div></div></>
    )
    
}
export default ProjectPage;