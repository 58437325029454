import React from 'react'
import { Link } from 'react-router-dom'
import Head from '../layout/Head'

const container = {
    display: "flex",
    justifyContent: "space-evenly",
    width: "80%",
    margin: "20px auto",
}
const center = {
    height:"95vh",
    minHeight:"95vh",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center"
}
export default function ErrorPage() {
    return (
        <>
        <Head title="Page Error"/>
        <div className="container" style={center}>
            <h1>This Is Embarrassing</h1>
            <h5>Oops...Sorry Watson the clue ain't here</h5>
            <div style={container}>
                <Link className="btn waves-effect amber black-text waves-light" to="/signin">Sign In</Link>
                <Link className="btn waves-effect amber black-text waves-light" to="/signin">Homepage</Link>
            </div>
        </div>
        </>
    )
}
