import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import firebase from './config/firebaseConfig';
// import {registerServiceWorker} from './registerServiceWorker.js'
import {applyMiddleware, createStore} from 'redux'
import combineRedux from './store/combineRedux'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
const rrfConfig = {
    userProfile:"users",
    useFirestoreForProfile: true,
    sessions: 'sessions',
    createFirestoreInstance,
    fileMetadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
        // upload response from Firebase's storage upload
        const {
          metadata: { name, fullPath }
        } = uploadRes
        // default factory includes name, fullPath, downloadURL
        return {
          name,
          fullPath,
          downloadURL
        }
      }
}
const store = createStore(combineRedux,{},applyMiddleware(thunk))
const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}
// render when firebase is loaded
ReactDOM.render(<Provider store={store}><ReactReduxFirebaseProvider {...rrfProps}><App /></ReactReduxFirebaseProvider></Provider>,document.getElementById('root'));
// registerServiceWorker();