// managing userProjects array in redux state
const initState = {
    // id:{title:string,description:string,ingredients:array,methods:array,author:string,tags:array,timestamp}
    globalProject:{}
}
const projectReducer = (state=initState,action) => {
    switch(action.type) {
        case 'ADD_PROJECT':
            state.globalProject[action.projectId] = action.project
            return state
        case 'DELETE_PROJECT':
            delete state.globalProject[action.projectId]
            return state
        case 'EDIT_PROJECT':
            state.globalProject[action.projectId] = action.project
            return state
        default:
            return state
    }
}
export default projectReducer