import React from 'react'
import parseDatenTime from './parseDatenTime.js'

export default function PostCards(props) {
    const {username,usertag,imageProfile,post,timestamp,likes,comments} = props.data
    
    const toggleIcon = (e) => {
        if (e.target.innerText === "favorite") {
            e.target.innerText = "favorite_border"
        }
        else if (e.target.innerText === "favorite_border") {
            e.target.innerText = "favorite"
        }
    }
    const datetime = timestamp.toDate()
    const datetimefunc = new parseDatenTime()
    return (
        <div className="card white black-text">
            <div className="valign-wrapper post-header">
                <img src={imageProfile} alt="profile-icon" className="circle profile-image" /> 
            <div>
                <p className="username">{username}</p>
                <p className="usertag">@{usertag}</p>
            </div>
            </div>
            <div className="post-container left-align">
                <h5>{post}</h5>
            </div>
            <p className="grey-text post-time right-align">{datetimefunc.toHHMM(datetime)} . {datetimefunc.toDDMMYY(datetime)}</p>
            <div className="collapsible z-depth-0">
                <div className="collapsible-header post-link-container">
                    <span className="valign-wrapper black-text"><i className="small material-icons deep-orange-text post-icon" onClick={(e) => toggleIcon(e)}>favorite_border</i>{likes>1000?`${likes/1000}k`:likes}</span>
                    <span className="valign-wrapper black-text"><i className="small material-icons deep-orange-text post-icon">chat_bubble_outline</i> {comments.length}</span>
                    <span className="valign-wrapper black-text"><i className="small material-icons deep-orange-text">reply</i></span>
                </div>
               
            </div>
        </div>
    )
}
