import { combineReducers } from "redux";
import authReducer from './authReducer'
import projectReducer from "./projectReducer";
import postReducer from "./postReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";


const combineRedux = combineReducers({
    auth:authReducer,
    project:projectReducer,
    posts:postReducer,
    firestore:firestoreReducer,
    firebase:firebaseReducer
})

export default combineRedux