import React, { useState } from 'react'
import Head from '../layout/Head'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signIn } from '../../store/actionCreator'
import { useFirebase, useFirestore } from 'react-redux-firebase'
// login to firebase and add user info into redux auth state
export default function SignIn() {
    const fb = useFirebase()
    const firestore = useFirestore()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const signin = () => {
        fb.login({email:username,password}).then(userCred => {
            const userId = userCred.user.uid
            return firestore.collection("users").doc(userId).get().then(doc => {
                // load user data into projects
                return {userId,user:doc.data()}
            })
        }).then(result => {
            const {userId,user} = result
            dispatch(signIn(userId,user))
        }).then(() => navigate('/', { replace: true })).catch(err => {
            if (err.code === "auth/user-not-found") {
                // add invalid class to username input
                document.querySelector("input#username").classList.add("invalid")
                document.querySelector("span#usernameMessage").setAttribute("data-error",err.message)
            }
            else if (err.code === "auth/wrong-password") {
                // add invalid class to password input
                document.querySelector("input#password").classList.add("invalid")
                document.querySelector("span#passwordMessage").setAttribute("data-error",err.message)
            }
            else {
                console.log(err.code,err.message)
            }
        })
    }
    return (
        <>
        <Head title="SHERL | Sign In"/>
        <div className="container">
        <h1>Sign in</h1>
        <div className="col s12">
            <div className="row">
                <div className="input-field s12">
                    <label htmlFor="username">Username (email)</label>
                    <input className="validate" type="email" name="username" id="username" onChange={(e) => setUsername(e.target.value)}/>
                    <span id="usernameMessage" className="helper-text" data-error=""></span>
                </div>
            </div>
            <div className="row">
                <div className="input-field s12">
                    <label htmlFor="password">Password</label>
                    <input className="validate" type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}/>
                    <span id="passwordMessage" className="helper-text" data-error=""></span>
                </div>
            </div>
            <button type="submit" className="btn green lighten-2" onClick={() => signin()}>Sign In</button>
        </div>
        </div>
        </>
    )
}