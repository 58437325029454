import React from 'react'
import {Helmet} from 'react-helmet'

export default function Head(props) {
    return (
    <Helmet>
        <link rel="apple-shortcut icon" href="/sherlicon192.png" />
        <link rel="shortcut icon" href="/sherlicon192.png" />
        <title>{props.title}</title>
    </Helmet>
    )
}
